
import { VoiceRecorder } from 'capacitor-voice-recorder';

document.addEventListener('DOMContentLoaded', function() {
    const isDesktop = window.innerWidth > 1024; 
    const voiceSendButton = document.getElementById('voiceSend');
    let isRecording = false; // State to track recording status

    // Function to add or remove the recording class from the body
    function toggleRecordingClass(recording) {
        if (recording) {
            document.body.classList.add('recording');
        } else {
            document.body.classList.remove('recording');
        }
    }

    if (isDesktop) {
    voiceSendButton.addEventListener('click', () => {
        if (!isRecording) {
            startRecording();
            toggleRecordingClass(true);
            isRecording = true;
        } else {
            stopRecording();
            toggleRecordingClass(false);
            isRecording = false;
        }
    });
} else {
    // Mobile functionality (hold to record)
    voiceSendButton.addEventListener('touchstart', () => {
        if (!isRecording) {
            startRecording();
            toggleRecordingClass(true);
            isRecording = true;
        }
    });

    voiceSendButton.addEventListener('touchend', () => {
        if (isRecording) {
            stopRecording();
            toggleRecordingClass(false);
            isRecording = false;
        }
    });

    voiceSendButton.addEventListener('touchcancel', () => {
        if (isRecording) {
            stopRecording();
            toggleRecordingClass(false);
            isRecording = false;
        }
    });
}
});


function toggleIcons(isRecording) {
    const micSendIcon = document.querySelector('.micSend');
    const micStopIcon = document.querySelector('.micStop');

    if (isRecording) {
        micSendIcon.style.display = 'none';
        micStopIcon.style.display = 'block';
    } else {
        micSendIcon.style.display = 'block';
        micStopIcon.style.display = 'none';
    }
}

async function startRecording() {
    await VoiceRecorder.requestAudioRecordingPermission(); // Ensure permission is granted
    VoiceRecorder.startRecording().then(() => {
        console.log("Recording started");
    }).catch(error => {
        console.error("Error starting recording:", error);
    });
}

async function stopRecording() {
    try {
        const result = await VoiceRecorder.stopRecording();
        if (result.value) {
            console.log('Recording stopped', result.value);
            handleAudioFile(result.value.recordDataBase64); // Assuming Base64 data needs handling
        }
    } catch (error) {
        console.error("Error stopping recording:", error);
    }
}

function handleAudioFile(base64Audio) {
    console.log("Base64 Audio Length:", base64Audio.length); // Debugging audio data length
    const audioElement = document.createElement('audio');
    audioElement.controls = true;

    // Ensure the Base64 string is correctly prefixed with a MIME type if not already provided
    if (base64Audio.startsWith('data:audio')) {
        audioElement.src = base64Audio;
    } else {
        audioElement.src = `data:audio/mp3;base64,${base64Audio}`; // Adjust MIME type as necessary
    }

    // Add error handling for audio playback
    audioElement.onerror = function() {
        console.error('Error in playback:', audioElement.error);
    };

    audioElement.volume = 1.0; // Ensure the volume is set to maximum

    const audioBlob = base64ToBlob(base64Audio, 'audio/mp3'); // Implement this function if not already
    const audioFile = new File([audioBlob], "recording.mp3", {type: 'audio/mp3'});

    // Create a new DataTransfer instance
    var dataTransfer = new DataTransfer();
    dataTransfer.items.add(audioFile);

    // Create a dummy input and set its files to our DataTransfer object's files
    var input = document.createElement('input');
    input.type = 'file';
    input.files = dataTransfer.files;

    window.sendPrivateAudio(input, audioElement);
}

function base64ToBlob(base64, mimeType) {
    try {
        console.log("Check: Starting conversion");

        // Handle both data URI and raw Base64 strings
        const base64Pattern = /^data:([a-z]+\/[a-z]+);base64,(.*)$/;
        let matches = base64.match(base64Pattern);
        let contentType, rawBase64;
        
        if (matches) {
            // If the base64 string includes a data URI
            contentType = matches[1];
            rawBase64 = matches[2];
        } else {
            // If the base64 string is raw
            contentType = mimeType || 'application/octet-stream';
            rawBase64 = base64;
        }

        const byteCharacters = atob(rawBase64);
        const byteArrays = [];
        const sliceSize = 512;

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        console.log("Check: Conversion successful");
        return blob;
    } catch (error) {
        console.error("Error converting base64 to blob:", error);
    }
}


function b64toBlob(b64Data, contentType, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}



    