
      import AC from 'agora-chat'
      
      const APP_ID = '711169900#1357075';
      const CHANNEL_NAME = 'Main Chat';
      const USER_ID = 'test_user';

const appKey = APP_ID; // Your App ID
const conn = new AC.connection({ appKey: appKey });

window.userDetails = {};
window.groupDetails = {};

async function getToken() {
    console.log("----------");
    let token = localStorage.getItem("userUuid")
    const response = await fetch(`https://amezianbackend-378a44d933c2.herokuapp.com/generateToken?userUuid=${token}`);
    const data = await response.json();
    console.log(data);
    return data.token;
}
console.log("checkChat");
window.accessChat = async function() {
    try {
        const token = await getToken();
        console.log("Received token:", token);
        // Ensure the connection is open and authenticated
        conn.open({
            user: localStorage.getItem('userID'),  // Retrieve user ID correctly from localStorage
            agoraToken: token,  // Ensure this token is valid and has the appropriate permissions
        }).then(() => {
            console.log("Connection established");

            const user = JSON.parse(localStorage.getItem('user'));
            window.uID = user.groupId;
            if (user && window.uID) {
                // Prepare the options for joining the group
                let options = {
                    groupId: window.uID
                };

                // Attempt to join the group
                conn.joinGroup(options).then(res => {
                    console.log("Joined group successfully:", res);
                }).catch(err => {
                    console.error("Error joining group:", err);
                }).finally(() => {
                    // Proceed to get history messages
                    /*
                    <div id="convDiv" class="convDiv">
                    <div class="convChat">
                        <img src="https://upcdn.io/12a1z3Z/raw/uploads/2024/07/30/4kSVQwkqBb-Ellipse%2090-2.png">
                        <div>
                            <p class="convChatName">Valérie Dupont</p>
                            <p class="convChatMessage">Hello</p>
                        </div>
                        <p class="convTime">14:54</p>
                    </div>
                </div>*/
                    
                    window.getHistoryMessages();
                });
            } else {
                console.log("User or groupId is missing");
            }
        }).catch(err => {
            console.error("Error establishing connection:", err);
        });
    } catch (error) {
        console.error("Error getting token:", error);
    }
}

window.getHistoryMessages = function(){
  conn.getHistoryMessages({
                        targetId: window.uID,
                        chatType: 'groupChat',
                        pageSize: 1000,
                        searchDirection: 'up',
                    }).then((res) => {
                        console.log("Messages", res.messages);
                        res.messages.forEach(message => {
                            const userId = message.from;
                            if (!window.userDetails[userId]) {
                                window.userDetails[userId] = {
                                    name: "", // Placeholder for the user's name
                                    lastName: "", // Placeholder for the user's last name
                                    profileImage: "" // Placeholder for the user's profile image
                                };
                            }
                        });

                        // Fetch all user details and wait for completion
                        Promise.all(Object.keys(window.userDetails).map(userId => fetchUserDetails(userId))).then(() => {
                        console.log("User details updated:", window.userDetails);

                        const userId = localStorage.getItem('userID'); // Retrieve current user's ID from localStorage
                        const messageContainer = document.getElementById('messages'); // The container to append messages to

                        document.querySelector("#messages").innerHTML = `<div class="fromMessage"><div class="fromUser">Hassan A.</div><div class="fromText">
            Bonjour,

Je suis Hassan, directeur du centre de médecine et nutrition Amezian. Avec mon équipe médicale, nous serons présents durant tout votre parcours.Nous sommes disponibles sur cette messagerie privée du lundi au vendredi, de 8h à 12h et de 14h à 18h.
<br><br>
Nous allons vous transmettre votre demande d’ordonnance à faire prescrire par le médecin en rapport avec votre objectif. Vous la trouverez dans l’onglet « Mes documents ».
<br><br>
Nous laissons toujours le choix à nos patientes : si vous souhaitez...
          </div></div>`;

                        const HALF_HOUR = 30 * 60 * 1000; // 30 minutes in milliseconds
                        let lastMessageTime = null;
                        let isFirstMessage = true;
                        res.messages.slice().reverse().forEach((message, index, array) => {
                            let messageDiv;
                            const isFirstMessageFromUser = index === 0 || array[index - 1].from !== message.from;
                            const hasTimeGap = index > 0 && (message.time - array[index - 1].time > HALF_HOUR);

                            // Always display time for the first message or after a time gap
                            if (isFirstMessage || hasTimeGap) {
                                const timeMessageDiv = document.createElement('div');
                                timeMessageDiv.className = 'timeMessage';

                                // Format the time to "HH:MM"
                                const messageDate = new Date(message.time);
                                const hours = messageDate.getHours().toString().padStart(2, '0');
                                const minutes = messageDate.getMinutes().toString().padStart(2, '0');
                                timeMessageDiv.textContent = `${hours}:${minutes}`;

                                // Append the timeMessageDiv before the actual message
                                messageContainer.appendChild(timeMessageDiv);

                                // Mark that the first message has been processed
                                isFirstMessage = false;
                            }

                            // Update the lastMessageTime
                            lastMessageTime = message.time;

                            if (message.type === "txt") {
                                // This is a text message
                                if (message.from === userId) {
                                    // This is a toMessage
                                    messageDiv = document.createElement('div');
                                    messageDiv.className = 'toMessage';
                                    messageDiv.textContent = message.msg; // Set text content of the message
                                } else {
                                    // This is a fromMessage
                                    messageDiv = document.createElement('div');
                                    messageDiv.className = 'fromMessage';
                                    messageDiv.innerHTML = `<div class="fromText">${message.msg.replace(/\n/g, '<br>')}</div>`;

                                    // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
                                    if (isFirstMessageFromUser || hasTimeGap) {
                                        messageDiv.classList.add(`fromUser-${message.from}`);
                                        messageDiv.innerHTML = `<div class="fromUser">${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}</div>` + messageDiv.innerHTML;
                                    }
                                }
                            } else if (message.type === "audio") {
                                // This is an audio message
                                messageDiv = document.createElement('div');
                                messageDiv.className = message.from === userId ? 'toMessage' : 'fromMessage';

                                const audioElement = document.createElement('audio');
                                audioElement.controls = true;
                                audioElement.crossOrigin = "anonymous";  // Ensures the audio is fetched with CORS policies

                                const sourceElement = document.createElement('source');
                                sourceElement.src = message.url;
                                sourceElement.type = "audio/mpeg";

                                audioElement.appendChild(sourceElement);
                                audioElement.innerHTML += "Your browser does not support the audio element.";

                                if (message.from !== userId) {
                                    const fromTextDiv = document.createElement('div');
                                    fromTextDiv.className = 'fromText';
                                    fromTextDiv.appendChild(audioElement);
                                    messageDiv.appendChild(fromTextDiv);

                                    // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
                                    if (isFirstMessageFromUser || hasTimeGap) {
                                        messageDiv.classList.add(`fromUser-${message.from}`);
                                        const fromUserDiv = document.createElement('div');
                                        fromUserDiv.className = 'fromUser';
                                        fromUserDiv.textContent = `${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}`;
                                        messageDiv.prepend(fromUserDiv);
                                    }
                                } else {
                                    messageDiv.appendChild(audioElement);
                                }
                            } else if (message.type === "file") {
                                // This is a file message
                                messageDiv = document.createElement('div');
                                messageDiv.className = message.from === userId ? 'toMessage' : 'fromMessage';

                                const fileLink = document.createElement('a');
                                fileLink.href = message.url;
                                fileLink.target = "_blank"; // Opens the file in a new tab
                                fileLink.textContent = message.filename; // Display the file name

                                if (message.from !== userId) {
                                    const fromTextDiv = document.createElement('div');
                                    fromTextDiv.className = 'fromText';

                                    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
                                    const fileExtension = message.filename.split('.').pop().toLowerCase();

                                    if (imageExtensions.includes(fileExtension)) {
                                        const imagePreview = document.createElement('img');
                                        imagePreview.src = message.url;
                                        imagePreview.alt = message.filename;
                                        imagePreview.style.maxWidth = "100%";
                                        imagePreview.style.maxHeight = "200px"; // Limit the size of the preview
                                        fromTextDiv.appendChild(imagePreview);
                                    } else {
                                        fromTextDiv.appendChild(fileLink);
                                    }

                                    messageDiv.appendChild(fromTextDiv);

                                    // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
                                    if (isFirstMessageFromUser || hasTimeGap) {
                                        messageDiv.classList.add(`fromUser-${message.from}`);
                                        const fromUserDiv = document.createElement('div');
                                        fromUserDiv.className = 'fromUser';
                                        fromUserDiv.textContent = `${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}`;
                                        messageDiv.prepend(fromUserDiv);
                                    }
                                } else {
                                    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
                                    const fileExtension = message.filename.split('.').pop().toLowerCase();

                                    if (imageExtensions.includes(fileExtension)) {
                                        const imagePreview = document.createElement('img');
                                        imagePreview.src = message.url;
                                        imagePreview.alt = message.filename;
                                        imagePreview.style.maxWidth = "100%";
                                        imagePreview.style.maxHeight = "200px"; // Limit the size of the preview
                                        messageDiv.appendChild(imagePreview);
                                    } else {
                                        messageDiv.appendChild(fileLink);
                                    }
                                }
                            }

                            messageDiv.setAttribute('data-time', message.time);
                            messageContainer.appendChild(messageDiv); // Append the created messageDiv to the container

                            // Add CSS for the first of type message from each user dynamically
                            if (!document.querySelector(`style.fromUser-style-${message.from}`)) {
                                let style = document.createElement('style');
                                style.innerHTML = `.fromUser-${message.from} .fromText:before { 
                                    content: '';
                                    display: block;
                                    width: 3.863vh;
                                    height: 3.863vh;
                                    position: absolute;
                                    top: 0;
                                    left: -0.86vw;
                                    background: url('${window.userDetails[message.from]?.profileImage || 'default-profile.png'}');
                                    background-size: cover;
                                }
                                .fromUser-${message.from} {
                                    margin-top: 2.5vh;
                                }`;
                                document.head.appendChild(style); // Append the style element to the head for CSS
                            }
                        });
                        

                        if ($(".page[type='loading']").hasClass("active")){
                          $(".page[type='loading']").removeClass("active");
                          $(".page[type='chat']").addClass("active");
                        }

                        var scrollableDiv = document.getElementById('chat');
                        setTimeout(() => {
                          scrollableDiv = document.getElementById('chat');
                          scrollableDiv.scrollTop = 10000000000;
                        }, "300");

                        setTimeout(() => {
                          scrollableDiv = document.getElementById('chat');
                          scrollableDiv.scrollTop = 10000000000;
                        }, "500");

                        setTimeout(() => {
                          scrollableDiv = document.getElementById('chat');
                          scrollableDiv.scrollTop = 10000000000;
                        }, "700");

                        setTimeout(() => {
                          scrollableDiv = document.getElementById('chat');
                          scrollableDiv.scrollTop = 10000000000;
                        }, "1000");
                        
                        

                            // Continue with other code that depends on updated userDetails
                            // You can add more logic here
                        }).catch(error => {
                            console.error("Error updating user details:", error);
                        });
                    }).catch(err => {
                        console.error("Error getting history messages:", err);
                    });
}

function fetchUserDetails(userId) {
    return fetch(`https://amezianbackend-378a44d933c2.herokuapp.com/api/user-details/${userId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`  // Assuming the token is stored in localStorage
        }
    })
    .then(response => response.json())
    .then(data => {
        if (window.userDetails[userId]) {
            window.userDetails[userId].name = data.name;
            window.userDetails[userId].lastName = data.lastName;
            window.userDetails[userId].profileImage = data.profileImage;
        }
    })
    .catch(error => console.error('Error fetching user details:', error));
}

// Attach event to send button
document.getElementById("sendMessageButton").addEventListener("click", sendMessage);

document.getElementById("messageInput").addEventListener("keydown", function(event) {
    if (event.key === "Enter") {
        event.preventDefault(); // Prevent the default action (e.g., adding a newline in a textarea)
        sendMessage(); // Trigger the send button click
    }
});

function sendMessage() {
  if (document.getElementById("messageInput").value.length > 0){
  const user = JSON.parse(localStorage.getItem('user'));
  const messageInput = document.getElementById("messageInput").value;
  
  const option = {
    chatType: "groupChat",
    type: "txt",
    to: user.groupId, // Change 'to' field based on the actual recipient
    msg: messageInput,
  };
  const msg = AC.message.create(option);

  // Add the message to the chat immediately
  const messagesDiv = document.getElementById("messages");

  // Get the last message element to check the time
  const lastMessageElement = messagesDiv.querySelector(".toMessage:last-of-type");
  const currentTime = new Date().getTime();
  let lastMessageTime = 0;

  if (lastMessageElement) {
    lastMessageTime = parseInt(lastMessageElement.getAttribute("data-time"));
  }

  const halfHourInMillis = 30 * 60 * 1000; // 30 minutes in milliseconds

  // Determine if the time message should be added
  const shouldAddTimeMessage = !lastMessageElement || (currentTime - lastMessageTime > halfHourInMillis);

  if (shouldAddTimeMessage) {
    const timeElement = document.createElement("div");
    timeElement.classList.add("timeMessage");
    timeElement.textContent = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    messagesDiv.appendChild(timeElement);
  }

  // Create and append the message element
  const messageElement = document.createElement("div");
  messageElement.classList.add("toMessage");
  messageElement.textContent = messageInput;
  messageElement.setAttribute("data-time", currentTime.toString());
  messagesDiv.appendChild(messageElement);

  conn.send(msg)
    .then(() => {
      console.log("Message sent:", messageInput);
      document.getElementById("messageInput").value = ''; // Clear input after sending
    })
    .catch((error) => {
      console.error("Send message failed", error);
      // Optionally remove the message if sending failed
      messagesDiv.removeChild(messageElement);
      if (shouldAddTimeMessage) {
        messagesDiv.removeChild(timeElement);
      }
    });
  }
}

window.sendPrivateAudio = function(input, audioElement){
  var file = AC.utils.getFileUrl(input); // Assuming AC.utils.getFileUrl can handle this setup
    var allowType = {
        'mp3': true,
        'amr': true,
        'wmv': true
    };

    if (file.filetype.toLowerCase() in allowType) {
        var option = {
            type: 'audio',
            file: file,
            length: parseInt(file.data.size / 1024), // Length in seconds or size
            chatType: "groupChat",
            to: user.groupId, // Change 'to' field based on the actual recipient
            onFileUploadError: function () {
                console.log('onFileUploadError');
            },
            onFileUploadProgress: function (e) {
                console.log('Upload progress', e);
            },
            onFileUploadComplete: function () {
                console.log('onFileUploadComplete');
            },
            ext: { file_length: file.data.size }
        };

        var msg = AC.message.create(option);
        conn.send(msg).then(() => {
            console.log('Voice message sent successfully');
            const messagesDiv = document.getElementById("messages");
            const messageElement = document.createElement("div");
            messageElement.classList.add("toMessage");
            messageElement.appendChild(audioElement);
            messagesDiv.appendChild(messageElement);
        }).catch((e) => {
            console.error("Failed to send voice message:", e);
        });
    }
}

window.sendPrivateFile = function(input) {
    var file = AC.utils.getFileUrl(input); // Get the file using Agora Chat utility
    var allowType = {
        'jpg': true,
        'jpeg': true,
        'png': true,
        'gif': true,
        'pdf': true,
        'doc': true,
        'docx': true,
        'xls': true,
        'xlsx': true,
        'mp3': true,
        'amr': true,
        'wmv': true,
        // Add any other file types you want to support
    };

    var fileType = file.filetype.toLowerCase();

    if (fileType in allowType) {
        var option = {
            type: 'file', // Generic file type
            file: file,
            filename: file.filename, // File name to display
            chatType: "groupChat",
            to: user.groupId, // Change 'to' field based on the actual recipient
            onFileUploadError: function () {
                console.log('onFileUploadError');
            },
            onFileUploadProgress: function (e) {
                console.log('Upload progress', e);
            },
            onFileUploadComplete: function () {
                console.log('onFileUploadComplete');
            },
            ext: { file_length: file.data.size } // Optional: Additional file metadata
        };

        var msg = AC.message.create(option);
        conn.send(msg).then(() => {
            console.log('File sent successfully');
            setTimeout(() => {
              window.getHistoryMessages();
            }, "1000");
            // Optionally display the file in the chat UI
        }).catch((e) => {
            console.error("Failed to send file:", e);
        });
    } else {
        console.error("Unsupported file type:", fileType);
    }
}

// Event listener for file send button
document.querySelector('.fileSend').addEventListener('click', function() {
    const fileInput = document.getElementById('fileInput'); // Assuming you have a file input element with this ID
    fileInput.click(); // Trigger file input click to open file picker

    fileInput.onchange = function() {
        if (fileInput.files.length > 0) {
            sendPrivateFile(fileInput);
        }
    };
});

conn.addEventHandler("connection&message", {
  onConnected: () => {
    console.log("Connect success!");
    const logDiv = document.getElementById("messages");
  },
  onDisconnected: () => {
    console.log("Disconnected!");
    const logDiv = document.getElementById("messages");
    logDiv.append("Disconnected from the chat.");
  },
  onTextMessage: (message) => {
    setTimeout(() => {
      window.getHistoryMessages();
    }, "500");
  },
  onAudioMessage: (message) => {
    window.getHistoryMessages();
  },
  onTokenWillExpire: () => {
    console.log("Token will expire soon.");
    // Optionally, refresh token here]
    window.accessChat();
  },
  onTokenExpired: () => {
    console.log("Token has expired.");
    const logDiv = document.getElementById("messages");
    logDiv.append("Token has expired, please log in again.");
    // Handle re-login or token refresh here
  },
  onError: (error) => {
    console.error("Chat error", error);
    const logDiv = document.getElementById("messages");
    logDiv.append("Error: " + error.message);
  }
});

// Call this function appropriately in your app's flow

/*
    // Initialize both RTC and RTM clients
    initializeRTC();
    initializeRTM();

      //joinChannel();
*/  
    